.Projects {
  padding: 28px;
  background: linear-gradient(to bottom right, #062b8e, #1865b8, #2badc3, #d9f4c9);
  display: flex;
  flex-direction: column;
  align-items: center;

  .titleContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    align-items: center;

    .img {
      height: 100px;
    }

    h1 {
      font-size: 36px;
      margin: 0;
      padding: 0;
      position: relative;
      top: -1px;
      color: white;
    }

    .line {
      width: 120%;
      margin: 12px 0;
      border-top: 2px white solid;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
  }

  .cardsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .projectCard {
      position: relative;
      display: flex;
      align-items: center;
      width: 300px;
      height: 420px;
      background-color: white;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      display: flex;
      flex-direction: column;
      font-family: 'Inconsolata';
      margin: 24px;

      .top {
        height: 120px;
        overflow: hidden;
        margin-bottom: 8px;

        .img {
          min-height: 120px;
          width: 100%;
          object-fit: cover;

          &.mcfitt {
            object-position: top;
          }
        }
      }

      .content {
        position: relative;
        margin: 10px 16px;

        .title {
          h3 {
            font-weight: bold;
          }
        }

        .subTitle {
          padding: 4px 0 12px 0;
          p {
            margin: 0;
            padding: 0;
            font-weight: bold;
          }
        }
      }
      .btnContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 280px;
        bottom: 0;
        margin: 8px;
        padding: 5px;
        cursor: pointer;
        background: linear-gradient(to bottom right, purple, blue);

        .button {
          color: white;
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: 650px) {
    .cardsContainer {
      .projectCard {
        transform: none!important;
      }
    }
  }
}
