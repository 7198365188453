.ScrollToTop {
  position: fixed;
  bottom: 20px;
  right: 28px;
  //   padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: 52px;
  border-radius: 50px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  cursor: pointer;
  z-index: 5000;

  svg {
    height: 20px;
    width: 20px;
    color: #696969;
  }
}

@media (max-width: 768px) {
  .ScrollToTop {
    height: 44px;
    width: 44px;
    right: 14px;
  }
}
