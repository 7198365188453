@media (min-width: 1150px) {
  .DropdownProfile {
    background: linear-gradient(128deg, #008fd1a9, #004a9fb4, #3a3187b9), url('./mtlback-bw.png');
    background-size: cover;
    background-position: center;

    position: absolute;

    top: 0;
    right: 8vw;
    display: flex;
    justify-content: center;

    height: 460px;
    width: 340px;

    border-radius: 0 0 60px 60px;
    border: 3px solid #ffc000;
    border-top: none;

    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

    z-index: 6000;

    .profilepic {
      position: absolute;
      bottom: 0;
      height: 80%;
    }
  }
}
//(min-width: 1150px) and
@media (min-width: 1150px) and (max-width: 1220px) {
  .DropdownProfile {
    right: 3.5vw;
  }
}

@media (max-width: 1150px) {
  .DropdownProfile {
    background: linear-gradient(128deg, #008fd1a9, #004a9fb4, #3a3187b9), url('./mtlback-bw.png');
    background-size: cover;
    background-position: center;

    position: relative;
    top: -140px;

    display: flex;
    justify-content: center;

    height: 220px;
    width: 220px;

    border-radius: 150px;
    border: 3px solid #ffc000;

    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    margin: auto;
    overflow: hidden;

    z-index: 0;

    .profilepic {
      position: absolute;
      bottom: 0;
      height: 85%;
    }
  }
}

@media (min-width: 1300px) {
  .DropdownProfile {
    width: 420px;
  }
}

@media (min-width: 750px) and (max-width: 880px) {
  .DropdownProfile {
    position: relative;
    top: -110px;
  }
}
