.AboutContainer {
  display: flex;
  flex-direction: row;
  background-color: #5076cc;
  width: 100%;

  .left {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: #284780;
    align-items: center;
    justify-content: center;

    h3 {
      color: white;
      padding: 0;
      margin: 0;
    }
  }

  .right {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    // width: 100%;

    .aboutMessage {
      position: relative;
      top: 30px;
      text-align: left;
      padding: 20px 0px 20px 80px;
      flex-basis: 0;
      flex-grow: 1;
      color: white;
      font-family: 'OpenSans';
      font-weight: bold;
      font-size: 16px;

      transition: 0.2s ease-in-out;

      &:hover {
        transform: translateY(-4px);
      }

      .quoteIcon {
        position: absolute;
        top: -14px;
        left: 28px;
        height: 48px;
      }
    }

    .interestsContainer {
      padding: 24px 24px 24px 36px;
      flex-basis: 0;
      flex-grow: 1;
      text-align: left;
      color: white;

      .interests {
        padding-bottom: 14px;

        .title {
          display: flex;
          position: relative;
          flex-direction: row;
          align-items: center;

          .icon {
            height: 32px;
            padding-right: 8px;
          }

          h4 {
            padding: 0 0 4px 0;
            margin: 0;
          }
        }

        .chips {
          .chipRow {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .AboutContainer {
    flex-direction: column;

    .left {
      flex-direction: row;
      width: 100%;
      padding: 0;

      .hockey {
        height: 80px;
        padding-left: 10px;
      }
    }

    .right {
      flex-direction: column;

      .aboutMessage {
        padding: 32px 20px 10px 30px;
        top: 0px;

        .quoteIcon {
          position: absolute;
          top: -8px;
          left: 10px;
          height: 48px;
        }
      }

      .interestsContainer {
        flex-direction: column;
        width: 100%;
      }
    }
  }
}

@media (min-width: 1100px) {
  .AboutContainer {
    .right {
      .aboutMessage {
        font-size: 18px;
      }
    }
  }
}

.InterestChip {
  background-color: #c1e8ff;
  color: black;
  display: flex;
  flex-grow: 1;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  margin: 3px 4px;
  height: 26px;

  transition: 0.2s ease-in-out;

  &:hover {
    background-color: #ffffff;
  }

  padding: 4px 16px 4px 12px;

  .icon {
    height: 22px;
    padding-right: 3px;
  }

  p {
    padding: 0;
    margin: 0;
    font-family: 'Oswald-Medium';
  }
}
