.Contact {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  background: linear-gradient(#002e49e7, #00345283), url('./mtl.jpg');
  background-size: cover;

  &::before {
    height: 50%;
    background-color: #0079be;
  }

  .contactCard {
    position: absolute;
    background-color: white;
    height: 500px;
    width: 350px;
    max-width: 600px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;

    #wave2 {
      transform: rotate(180deg);
      min-height: 180px;
      max-height: 250px;
      width: 100%;
      object-fit: cover;
      position: absolute;
      bottom: -25px;
      left: 0;
    }

    .top {
      position: relative;
      width: 100%;
      .img {
        max-height: 150px;
        width: 100%;
        object-fit: cover;
      }
    }

    .profile {
      height: 140px;
      width: 140px;
      border-radius: 100px;
      overflow: hidden;
      position: relative;
      top: -80px;
      border: 3px solid #ffffff;

      .profileimg {
        width: 165px;
        object-fit: cover;
        position: relative;
        top: -44px;
        left: -15px;
      }
    }

    .titleContent {
      position: relative;
      top: -74px;

      h2 {
        color: #002060;
        margin: 0;
      }

      h4 {
        color: #056dbc;
        margin: 0;
      }
    }

    .emails {
      position: relative;
      top: -58px;
      display: flex;
      flex-direction: column;
      z-index: 5;

      .emailTitle {
        color: #424242;
      }

      p {
        margin: 0;
      }

      a {
        color: black;

        &:hover {
          color: #00589a;
        }
      }
    }

    .socialContainer {
      position: absolute;
      //   top: -80px;
      bottom: 28px;
      background: linear-gradient(128deg, #008fd1, #004a9f, #3a3187);
      border: 3px solid #ffc000;
      border-radius: 50px;
      padding: 2px 20px;
      margin: 20px 0;
      z-index: 5;
      a {
        color: white;
        svg {
          margin: 8px 12px;

          font-size: 24px;
        }

        &:hover {
          color: #53f376;
        }
      }
    }
  }
}
