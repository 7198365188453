@import '~bootstrap/scss/bootstrap';

html {
  scroll-behavior: smooth;
}

// :target:before {
//   content: '';
//   display: block;
//   height: 50px;
//   margin: -50px 0 0;
// }

.App {
  text-align: center;
  font-family: 'Oswald-Medium';

  // @media (min-width: 960px) {
  //   .anchor {
  //     position: relative;
  //     top: -48px;
  //   }
  // }
}
