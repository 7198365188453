.HomeContainer {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  padding-bottom: 80px;

  background: url('./white_header_background.jpg');
  background-size: cover;

  #wave {
    min-height: 180px;
    max-height: 250px;
    width: 100%;
    object-fit: cover;
  }

  .main {
    display: flex;
    flex-direction: column;
    font-family: 'Oswald-Medium';
    align-items: flex-start;
    margin-left: 10%;
    position: relative;
    top: -50px;

    .titleContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-size: 54px;
        padding: 0;
        margin: 0;
        color: #002060;
      }

      h2 {
        font-size: 38px;
        color: #00589a;
      }
    }

    .welcomeStatement {
      font-size: 21px;
      padding: 12px 0;
      margin: 0;
      color: grey;
    }

    .socialContainer {
      background: linear-gradient(128deg, #008fd1, #004a9f, #3a3187);
      border: 3px solid #ffc000;
      border-radius: 50px;
      padding: 2px 20px;
      margin: 20px 0;
      a {
        color: white;
        svg {
          margin: 8px 12px;

          font-size: 24px;
        }
      }
    }

    @media (min-width: 1150px) {
      .socialContainer {
        position: absolute;
        top: -28px;
        left: -10vw;
        display: flex;
        flex-direction: column;
        border: 3px solid #ffc000;
        border-left: none;
        border-radius: 0px 20px 20px 0;
        padding: 16px 4px;

        a {
          color: white;
          svg {
            margin: 12px 12px;

            font-size: 25px;
          }
          transition: 0.1s ease-in-out;

          &:hover {
            color: #c4ff80;
            transform: scale(1.1);
          }
        }
      }
    }

    .contactDownloadContainer {
      .contact {
        color: white;
        background-color: #00589a;
        padding: 4px 20px;
        margin: 0 20px 0 0;
        border-radius: 20px;

        &:hover {
          text-decoration: none;
          background-color: #0085eb;
        }
      }

      .download {
        color: #002060;

        &:hover {
          text-decoration: none;
          color: #0085eb;
        }
      }
    }
  }

  .scrollDown {
    position: absolute;
    bottom: 8px;
    width: 100%;

    p {
      color: #004a9f;
      padding: 0;
      margin: 0;
    }

    svg {
      font-size: 24px;
      color: #ffc000;
    }
  }

  @media (max-width: 1150px) {
    .main {
      align-items: center;
      margin: 0;
      top: -120px;

      .titleContent {
        align-items: center;

        h1 {
          font-size: 54px;
        }
        h2 {
          font-size: 40px;
        }
      }
    }
  }

  @media (max-width: 750px) {
    .main {
      align-items: center;
      margin: 0;
      top: -120px;

      .titleContent {
        align-items: center;

        h1 {
          font-size: 40px;
        }
        h2 {
          font-size: 28px;
        }
      }
    }
  }

  @media (min-width: 750px) and (max-width: 880px) {
    .main {
      top: -90px;
      .titleContent {
        h1 {
          font-size: 54px;
        }
        h2 {
          font-size: 40px;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .HomeContainer {
    padding-bottom: 0px;
  }
}
