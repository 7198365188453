.Navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: #0099ff;
  z-index: 200;

  .bootstrapNav {
    padding: 0;

    .nav-link {
      color: white;
      font-family: 'Oswald-Medium';
      padding: 8px 20px;

      &.active {
        color: #042e88;
      }
    }

    button {
      position: absolute;
      right: 14px;
      top: 8px;
      border: none;
      outline: none;
      background-color: white;
      border-radius: 50%;
      height: 44px;
      width: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

      span {
        height: 20px;
        width: 20px;
      }
    }
  }

  @media (min-width: 768px) {
    .bootstrapNav {
      padding: 4px;

      button {
        display: none;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1150px) {
    .bootstrapNav {
      display: inline-block;
      float: none;
      vertical-align: top;

      .nav-link {
        padding: 8px 24px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 842px) {
    .bootstrapNav {
      .nav-link {
        padding: 8px 19px;
      }
    }
  }
}
