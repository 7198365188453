.OnlineCourses {
  position: relative;
  z-index: 0;
  width: 100%;
  background: linear-gradient(to bottom right, #272727bd, #272727bd), url('./olympicstadium.png');
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  // height: 500px;

  .titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 28px 0 18px 0;

    .title {
      display: flex;
      flex-direction: row;
      border-bottom: 2px solid #ffc000;

      h1 {
        color: white;
        font-size: 32px;
        padding-right: 6px;
        margin: 0 0 6px 0;
      }

      .icon {
        height: 40px;
        padding-right: 8px;
      }
    }

    .subtitle-totalhrs {
      color: white;
      font-size: 18px;
      margin-top: 3px;
    }
  }

  .cards {
    margin: 0 100px;
    padding: 0;
    position: relative;
    // width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 20px;

    .animated {
      position: relative;
      z-index: 5;
    }

    .card {
      position: relative;
      z-index: 20;
      width: 320px;
      height: 290px;
      background: #f3f3f3;
      display: flex;
      margin: 22px 50px;
      border: none;
      border-radius: 0;
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

      .imgBx {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(107, 107, 107);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: 0.5s ease-in-out;

        .img {
          position: relative;
          top: 32px;
          max-width: 90px;
          transition: 0.5s ease-in-out;
        }

        p {
          display: none;
          text-align: left;

          &.smaller {
            font-size: 13px;
          }
        }
      }

      .imgBx::before {
        content: attr(data-text);
        position: absolute;
        top: 40px;
        bottom: 0px;
        left: 0;
        height: 100%;
        width: 100%;
        text-align: center;
        font-size: 70px;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.219);
      }

      .content {
        position: absolute;
        right: 0;
        width: calc(100% - 28px);
        height: 100%;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        font-family: 'Inconsolata';

        .titleComponent {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 100%;
          padding-right: 24px;
          padding-bottom: 8px;
          h3 {
            margin-bottom: 5px;
            font-size: 22px;
            font-weight: bold;
          }

          h5 {
            font-size: 14px;
          }
        }

        p {
          margin: 0 0 6px 0;
          padding: 0;
        }

        .links {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-bottom: 10px;

          a {
            display: inline-block;
            margin: 10px 3px 5px 0;
            padding: 5px 10px;
            background: #333;
            text-decoration: none;
            color: #fff;

            &:hover {
              color: #ffd92d;
            }
          }
        }
      }
    }

    .card:hover {
      .imgBx {
        width: 100px;
        height: 270px;
        left: -75px;
        top: calc(50% - 135px);
        background: #ff0057;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

        .img {
          top: 0px;
          max-width: 75px;
          margin: 10px;
        }

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        p {
          font-family: 'Inconsolata';
          display: flex;
          margin: 0;
          padding: 0;
          color: white;

          &.hours {
            font-weight: bold;
          }
        }
      }

      .imgBx::before {
        content: none;
      }
    }
  }

  @media (max-width: 992px) {
    .cards {
      width: 100%;
      margin: 0px 0px;
      flex-direction: column;
      align-items: center;

      .card {
        width: 400px;
      }
    }
  }

  @media (max-width: 768px) {
    .cards {
      .card {
        margin: 0px 0px;
        position: relative;
        z-index: 50;
        margin: 20px;
        max-width: 300px;
        flex-direction: column;
        height: auto;
        pointer-events: none!important;


        .imgBx {
          position: relative;
          background: #ff0057;
          flex-direction: column;

          .img {
            top: 0px;
            height: 65px;
            margin: 12px 8px 8px 8px;
          }

          .subContent {
            width: 100%;
            position: relative;
            padding-bottom: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-family: 'Inconsolata';
            font-weight: bold;

            .hours {
              padding-bottom: 6px;
            }

            .skills {
              display: flex;
              flex-direction: row;
              white-space: pre;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;

              p + p::before {
                content: ' • ';
              }
            }

            p {
              margin: 1px 0;
              display: flex;
              color: white;
              font-size: 16px;
            }
          }
        }

        .imgBx::before {
          content: none;
        }

        .content {
          position: relative;
          width: 100%;
          text-align: center;

          .titleComponent {
            padding-right: 0;
          }

          .links {
            position: relative;
            justify-content: center;
            pointer-events: visible!important;
          }
        }
      }
      .card:hover {
        .imgBx {
          width: 100%;
          height: auto;
          left: 0;
          top: 0;
          box-shadow: none;       
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .OnlineCourses {
    .titleContainer {
      .title {
        h1 {
          font-size: 26px;
          padding-right: 6px;
        }

        .icon {
          height: 35px;
        }
      }
    }
  }
}
