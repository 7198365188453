@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url('./assets/fonts/Oswald-Regular.ttf') format('truetype'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'Oswald-Medium';
  src: local('Oswald-Medium'), url('./assets/fonts/Oswald-Medium.ttf') format('truetype'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'Oswald-Light';
  src: local('Oswald-Light'), url('./assets/fonts/Oswald-Light.ttf') format('truetype'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'Oswald-SemiBold';
  src: local('Oswald-SemiBold'), url('./assets/fonts/Oswald-SemiBold.ttf') format('truetype'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url('./assets/fonts/OpenSans-Regular.ttf') format('truetype'); /* Super Modern Browsers */
}

@font-face {
  font-family: 'Inconsolata';
  src: local('Inconsolata'), url('./assets/fonts/Inconsolata-VariableFont_wdth\,wght.ttf') format('truetype'); /* Super Modern Browsers */
}

*,
html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}




/*
 *  STYLE 7
 */

 *::-webkit-scrollbar-track
 {
   /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
   background-color: #F5F5F5;
   /* border-radius: 10px; */
 }
 
 *::-webkit-scrollbar
 {
   width: 16px;
   background-color: #F5F5F5;
 }
 
 *::-webkit-scrollbar-thumb
 {
  background-color: #F90;	
  border-radius: 10px;
	background-image: -webkit-linear-gradient(45deg,
	                                          rgba(255, 255, 255, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(255, 255, 255, .2) 50%,
											  rgba(255, 255, 255, .2) 75%,
											  transparent 75%,
											  transparent)
 }

 @media screen and (min-width: 3000px) {
  html {
    -moz-transform: scale(1.50, 1.50);
    zoom: 1.5;
    zoom: 150%;
  }
}
