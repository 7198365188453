.Education {
  position: relative;
  width: 100%;
  background-color: #090f27;
  display: flex;
  flex-direction: column;

  .titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 28px 0 16px 0;

    .title {
      display: flex;
      flex-direction: row;
      border-bottom: 2px solid #ffc000;

      h1 {
        color: white;
        font-size: 32px;
        padding-right: 6px;
        margin: 0 0 6px 0;
      }

      .icon {
        height: 40px;
        padding-right: 8px;
      }
    }
  }

  .educationCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 28px;

    /* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
    .flip-card {
      background-color: transparent;
      position: relative;
      width: 300px;
      height: 340px;
      border: none;
      perspective: 1000px; /* Remove this if you don't want the 3D effect */
      margin: 20px 36px;
    }

    /* This container is needed to position the front and back side */
    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    /* Do an horizontal flip when you move the mouse over the flip box container */
    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    .frontContent {
      height: 340px;
      width: 300px;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top {
        background: url('./mcgill.jpg');

        background-size: cover;
        background-position: center 70%;
        height: 100px;

        &.abbott {
          background: url('./abbott.jpeg');
          background-size: cover;
          background-position: center 15%;
        }

        &.stt {
          background: url('./sttpic.jpg');
          background-size: cover;
          background-position: center 40%;
        }
      }
      .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .schoolLogo {
          height: 60px;
        }

        .textContent {
          padding-bottom: 16px;
          p {
            font-family: 'Oswald';
            margin: 0;
            padding: 0;
            font-size: 16px;
          }
          .title {
            font-family: 'Oswald-Medium';
            font-size: 20px;
          }
        }
      }
      .bottom {
        background-color: red;
        height: 48px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.abbott {
          background-color: #1e90ff;
        }

        &.stt {
          background-color: #219b09;
        }
      }
    }

    /* Position the front and back side */
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden; /* Safari */
      backface-visibility: hidden;
    }

    /* Style the front side (fallback if image is missing) */
    .flip-card-front {
      background-color: rgb(255, 255, 255);
      color: black;

      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
      border: none;
      border-radius: 20px;
      overflow: hidden;
    }

    /* Style the back side */
    .flip-card-back {
      border: 4px solid red;
      background-color: white;

      &.abbott {
        border: 4px solid #1e90ff;
      }

      &.stt {
        border: 4px solid #219b09;
      }

      color: black;
      transform: rotateY(180deg);

      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
      border-radius: 20px;
      overflow: hidden;

      .courses {
        padding-top: 12px;

        h3 {
          margin: 0;
          padding: 0 0 6px 0;
          font-size: 20px;
          text-decoration: underline;
        }

        .courseList {
          text-align: left;
          padding-left: 12px;
          p {
            margin: 0;
            padding: 0;
            font-size: 13px;
            font-family: 'OpenSans';
          }

          hr {
            margin: 4px 16px 4px 4px;
            border-top: 0.5px solid white;
          }
        }
      }
    }
  }
}
